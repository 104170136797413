<template>
    <v-main>
        <v-container>
            <div class="text-h4">Relocations</div>
            <v-row>
                <v-col cols="7">
                </v-col>
                <v-col cols="5" class="d-flex justify-end mt-0 pt-0 pb-0 mt-0">
                    <v-btn
                        color="primary"
                        small
                        :to="{name: 'newRelocation'}"
                    >
                        New Relocation
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-alert outlined type="info">
                        Could not find any active relocations
                    </v-alert>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
    import { computed } from '@vue/composition-api';

    export default {
        name: 'Relocations',
        components: {},
        setup(_, { root }) {
            const step = computed(() => root.$store.getters['getStep']);

            return { step };
        }
    }
</script>
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMain,[_c(VContainer,[_c('div',{staticClass:"text-h4"},[_vm._v("Relocations")]),_c(VRow,[_c(VCol,{attrs:{"cols":"7"}}),_c(VCol,{staticClass:"d-flex justify-end mt-0 pt-0 pb-0 mt-0",attrs:{"cols":"5"}},[_c(VBtn,{attrs:{"color":"primary","small":"","to":{name: 'newRelocation'}}},[_vm._v(" New Relocation ")])],1)],1),_c(VRow,[_c(VCol,[_c(VAlert,{attrs:{"outlined":"","type":"info"}},[_vm._v(" Could not find any active relocations ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }